<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="margin-bottom:20px">
      <CCol>
        <CButton
          color="success"
          variant="outline"
          square
          size="sm"
          @click="onClickNewJournal()">
          Buat Journal baru
        </CButton>&nbsp;&nbsp;&nbsp;
        <CButton
          color="success"
          variant="outline"
          square
          size="sm"
          @click="onClickDownloadJournal()">
          Download
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <div style="font-size:x-small; margin-bottom:10px">Perhatian: Catatan jurnal digunakan utk follow-up dan doa. Masukkan informasi garis besar saja. Mohon jangan memasukkan informasi yang terlalu pribadi. Selain pembimbing ybs, seluruh informasi jurnal hanya bisa di-akses oleh Tim Pembina Sion Academy.
      </div>
    </CRow>
    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        :items-per-page="30"
        hover
        pagination>
        <template #show_note="{item, index}">
          <td class="py-2">
            {{item.note}}
          </td>
        </template>
        <template #show_edit="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="onClickEdit(item, index)">
              Edit
            </CButton>
          </td>
        </template>
        <template #show_delete="{item, index}">
          <td class="py-2">
            <CButton
              color="danger"
              variant="outline"
              square
              size="sm"
              @click="onClickDelete(item, index)">
              Delete
            </CButton>
          </td>
        </template>      
      </CDataTable>
    </CRow>
  </div>

  <CModal :show.sync="showDialog" :title="title" hide-footer>
    <div style="padding: 10px">
      <CRow style="width:320px; margin:10px;" >
        <CCol><label for="datepicker">Tanggal pertemuan</label></CCol>
        <CCol><Datepicker placeholder="Pilih tanggal" v-model="record.meetingDate" format="yyyy-MM-dd"/></CCol>
      </CRow>

      <CRow style="width:320px; margin:10px;" >
        <CCol><label for="timepicker">Jam mulai</label></CCol>
        <CCol>
          <vue-timepicker :format="timepickFormat" v-model="timepickStart" :minute-interval="15"></vue-timepicker>
        </CCol>
      </CRow>

      <CRow style="width:320px; margin:10px;" >
        <CCol><label for="timepicker">Jam selesai</label></CCol>
        <CCol>
          <vue-timepicker :format="timepickFormat" v-model="timepickStop" :minute-interval="15"></vue-timepicker>
        </CCol>
      </CRow>

      <CSelect
        id="selectModule"
        label="Modul"
        :options="moduleOptions"
        :value.sync="selectedModule"
        @change="onSelectModuleChange"
        horizontal
      />
      <CSelect
        label="Sesi ke"
        :options="sessionOptions"
        :value.sync="selectedSession"
        horizontal
      />

      <div style="margin-bottom:15px">
        <multiselect
          label="name"
          track-by="name"
          v-model="selectedAttendees"
          :options="attendeesOptions"
          placeholder="Pilih yang hadir"
          multiple>
        </multiselect>
      </div>

      <CInput
        label="Ketik nama bila tidak ada di daftar"
        v-model="record.unlistedAttendees"
        horizontal
      />

      <CTextarea
        label="Catatan"
        v-model="record.note"
        horizontal
        rows="3"
      />
    </div>

    <div slot="footer">
      <CButton @click="dialogClose()">Batal</CButton>
      <CButton @click="dialogSave()" color="success">Simpan</CButton>
    </div>
  </CModal>
</div>
</template>

<script>
import api from "../../api/api"
import Datepicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

  const fields = [
    { 
      key: 'meetingDateFormatted', 
      label: 'Tanggal', 
      _style: 'min-width:110px'
    },
    { 
      key: 'startTime', 
      label: 'Mulai', 
      _style: 'min-width:60px'
    },
    { 
      key: 'moduleName', 
      label: 'Modul', 
      _style: 'min-width:200px'
    },
    { 
      key: 'noSesi', 
      label: 'Sesi', 
      _style: 'min-width:50px'
    },
    { 
      key: 'attendeesNames', 
      label: 'Yang hadir', 
      _style: 'min-width:100px'
    },
    { 
      key: 'show_note', 
      label: 'Catatan', 
      _style: 'min-width:200px'
    },
    { 
      key: 'show_edit', 
      label: '', 
      _style: 'width:1%'
    },
    { 
      key: 'show_delete', 
      label: '', 
      _style: 'width:1%'
    }
  ]

  export default {
    components: { Multiselect, Datepicker, VueTimepicker },

    data () {
      return {
        showLoading: false,
        items: [],
        fields,

        showDialog: false,
        title: '',
        isEdit: true,
        record: '',
        user: '',

        modules: [],
        moduleOptions: ["a","b","c"],
        sessionOptions: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],

        selectedModule: '',
        selectedSession: '',

        selectedAttendees: null,
        attendeesOptions: [],

        timepickFormat: 'HH:mm',
        timepickStart: {
          HH: '00',
          mm: '00'
        },
        timepickStop: {
          HH: '00',
          mm: '00'
        }
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        this.listMember()
      }
    },

    methods: {
      async listMember () {
        this.showLoading = true
        let apiRes = await api.listMember()
        this.showLoading = false

        if (apiRes.result === 'OK'){
          this.attendeesOptions = apiRes.members
          this.listModule()
        }
        else {
          console.log("listMember ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async listModule () {
        this.showLoading = true
        let apiRes = await api.listModule()
        this.showLoading = false

        if (apiRes.result === 'OK'){
          this.moduleOptions = apiRes.moduleNames
          this.modules = apiRes.modules

          if(this.moduleOptions.length > 0){
            this.selectedModule = this.moduleOptions[0]
            this.selectedSession = 1
          }

          this.listJournalPa()
        }
        else {
          console.log("listModule ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async listJournalPa () {
        this.items = []
        this.showLoading = true
        let apiRes = await api.listJournalPA(this.user.id, 'X')
        this.showLoading = false

        if (apiRes.result === 'OK')
          this.items = apiRes.items
        else {
          console.log("listJournalPa ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onSelectModuleChange(){
        //Not used; max session set to 10
        //this.sessionOptions= []
        //var totalSesi = this.modules[document.getElementById("selectModule").selectedIndex].nbMeeting
        //for(var i=0; i<totalSesi; i++) this.sessionOptions.push(i+1)
        //document.getElementById("selectModule").selectedIndex = 0
        //this.selectedSession = 1
      },

      onClickEdit (item) {
        this.isEdit = true
        this.title = "Edit Journal"
        this.record = item
        this.selectedAttendees = item.attendees

        var idx = 0
        for(var i=0; i<this.modules.length; i++){
          if(this.modules[i].moduleNo === item.moduleNo) idx = i
        }
        document.getElementById("selectModule").selectedIndex = idx
        this.selectedSession = item.noSesi

        console.log("=>ITEM: " + JSON.stringify(item))
        console.log("=>HH: " + item.startTime.substring(0,2))

        this.timepickStart.HH = item.startTime.substr(0,2)
        this.timepickStart.mm = item.startTime.substr(3,2)
        this.timepickStop.HH = item.stopTime.substr(0,2)
        this.timepickStop.mm = item.stopTime.substr(3,2)

        this.showDialog = true
      },

      async onClickDelete (item) {
        this.record = item
        this.$confirm("Yakin untuk menghapus journal ini?").then(() => {
          this.executeDeleteJournal()
        });
      },

      async executeDeleteJournal(){
        this.showLoading = true
        let apiRes = await api.deleteJournalPA(this.user.id, this.record.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Record sudah dihapus!")
          this.listJournalPa()
        } 
        else {
          console.log("deletePeriod ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }      
      },

      dialogClose(){
        this.showDialog = false
      },

      async dialogSave(){
        if(this.record.note.trim()==='' || this.selectedModule==='' || this.selectedSession==='')
          this.$alert("Semua data harus dimasukkan")

        else if(this.selectedAttendees.length===0 && this.record.unlistedAttendees==='')
          this.$alert("Siapa yang hadir harus dimasukkan. Pilih dari daftar atau ketik nama")

        else if(this.timepickStart.HH===this.timepickStop.HH && this.timepickStart.mm===this.timepickStop.mm)
          this.$alert("Masukkan jam mulai dan selesai")

        else{
          var attendeeNames = []
          if(this.record.unlistedAttendees!==undefined && this.record.unlistedAttendees!==''){
            var uAttendees = this.record.unlistedAttendees.split(",")
            for(var i=0; i<uAttendees.length; i++) {
              uAttendees[i] = uAttendees[i].trim()
              if(uAttendees[i]!=='')
                this.selectedAttendees.push({name:uAttendees[i]})
            }
          }

          this.record.attendees = this.selectedAttendees
          this.record.moduleName = this.modules[document.getElementById("selectModule").selectedIndex].moduleName
          this.record.moduleNo = this.modules[document.getElementById("selectModule").selectedIndex].moduleNo
          this.record.moduleId = this.modules[document.getElementById("selectModule").selectedIndex].id
          this.record.noSesi = this.selectedSession

          var meetingDate = Date.parse(this.record.meetingDate)
          if(isNaN(meetingDate)) meetingDate = this.record.meetingDate
          this.record.meetingDate = meetingDate + (8*3600*1000)

          this.record.startTime = this.timepickStart.HH + ":" + this.timepickStart.mm
          this.record.stopTime = this.timepickStop.HH + ":" + this.timepickStop.mm

          console.log("=>RECORD: " + JSON.stringify(this.record))

          this.showDialog = false

          if(this.isEdit){
            this.showLoading = true
            let apiRes = await api.updateJournalPA(this.user.id, this.record)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listJournalPa()
            } 
            else {
              console.log("updateJournalPA ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
          else{
            this.showLoading = true
            let apiRes = await api.createJournalPA(this.user.id, this.record)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listJournalPa()
            } 
            else {
              console.log("createJournalPA ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
        }
      },

      onClickNewJournal(){
        this.isEdit = false
        this.title = "Buat Journal baru"

        this.selectedAttendees = []
        var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/')
        this.record = { meetingDate: utc, noSesi: 1, note: '', unlistedAttendees: ''}

        this.timepickStart.HH = 0
        this.timepickStart.mm = 0
        this.timepickStop.HH = 0
        this.timepickStop.mm = 0

        this.showDialog = true
      },

      onClickDownloadJournal(){
        var csvFileData = [
           //['Cristiano Ronaldo', 'Footballer']
        ];
        for(var i=0; i<this.items.length; i++){
          var item = []
          item.push(this.items[i].meetingDateFormatted)
          item.push(this.items[i].startTime)
          item.push(this.items[i].stopTime)
          item.push(this.items[i].moduleName)
          item.push(this.items[i].moduleNo + "-" + this.items[i].noSesi)

          var attendees = ""
          for(var k=0; k<this.items[i].attendees.length; k++){
            attendees += this.items[i].attendees[k].name
            if(k<this.items[i].attendees.length-1) attendees += ", "
          }
          item.push(attendees)
          var note = this.items[i].note.replace(/[\n\r]/g,' ')
          item.push(note)
          csvFileData.push(item)
        }
        var csv = 'Tanggal\tMulai\tSelesai\tModul\tSesi\tPeserta\tCatatan\n'
    
        csvFileData.forEach(function(row) {
                csv += row.join('\t');
                csv += "\n"
        });
        //document.write(csv)
       
        var hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'Jurnal-Pemuridan-' + this.user.name + '.csv'
        hiddenElement.click()
      },

    },

  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
